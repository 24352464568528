export default [
    {
        name: "统计概览",
        variant: "danger",
        route: {
            name: "project-cabr-dashboard",
            params: {
                project_id: 7
            }
        },
        disabled: false,
    },
    {
        name: "风险事件",
        variant: "primary",
        route: {
            name: 'index-diagnosis-risk',
            params: {
                project_id: 7
            }
        },
        disabled: false,
    },
    {
        name: "故障列表",
        variant: "primary",
        route: {
            name: 'FunMenu_AgentList'
        },
        disabled: false,
    },
    {
        name: "通用诊断",
        variant: "success",
        route: {
            name: "DiagnosisGeneral"
        },
        disabled: false,
    },
    {
        name: "专业诊断",
        variant: "primary",
        route: {
            name: "DiagnosisSpeciality"
        },
        disabled: false,
    },
    // {
    //     name: "智能诊断",
    //     variant: "warning",
    //     route: {
    //         name: ""
    //     },
    //     disabled: false,
    // },
    {
        name: "关系图谱",
        variant: "info",
        route: {
            name: "FunMenu_relationGraph",
        },
        disabled: false,
    },
    {
        name: "实体监控",
        variant: "success",
        route: {
            name: "FunMenu_PhyMonitor",
            // name: "",
        },
        disabled: false,
    },
    {
        name: "KPI分析",
        variant: "warning",
        route: {
            name: 'index-project-kpi-analyse',
            params: {
                project_id: 7
            }
        },
        disabled: false,
        // children: [
            // {
            //     name: "KPI分析1",
            //     variant: "warning",
            //     route: {
            //         name:"FunMenu_kpiTest",
            //     },
            // }
        // ],
    },
    {
        name: "报警管理",
        variant: "success",
        route: {
            name: "index-diagnosis-alarm",
        },
        disabled: false,
    },
    {
        name: "管理设定",
        variant: "success",
        route: {
            name: ""
        },
        disabled: true,
    },
]
